import { Formik } from "formik";
import { navigate } from "gatsby";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import React, { useRef } from "react";
import { useMutation } from "react-query";
import { CustomOutlineButton } from "../../components/custom-button";
import CustomPasswordInput from "../../components/custom-password-input";
import { validatePassword } from "../../utils/password-validate";
import { cognitoForgotPasswordSubmit } from "../../utils/user-auth-provider";

const NewPasswordInputComponent = ({ email = "" }) => {
  const formRef = useRef("");

  const handleOnKeyPress = (e) => {
    if (e.keyCode === 13) {
      formRef.current.handleSubmit();
      formRef.current.setTouched({ password: false, confirmPassword: false });
    }
  };

  const handleSelectField = (name, state) => {
    const currentValue = formRef.current.touched;
    formRef.current.setTouched({ ...currentValue, [name]: state });
  };

  const { enqueueSnackbar } = useSnackbar();
  const useResetPassword = useMutation(
    (data) => cognitoForgotPasswordSubmit(data.email, data.otp, data.password),
    {
      onSuccess: () => {
        enqueueSnackbar("Your password has been changed", {
          variant: "success",
        });
        navigate("/login");
      },
      onError: (err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    }
  );

  const handleResetPassword = () => {
    useResetPassword.mutate({
      email: formRef.current.values.email,
      otp: formRef.current.values.otp,
      password: formRef.current.values.password,
    });
  };

  const handleBlur = (target) => {
    if (!validatePassword(target.value).verified) {
      formRef.current.setFieldError(target.name, "Invalid password");
    }
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          password: "",
          confirmPassword: "",
          otp: "",
          email,
        }}
        validate={(values) => {
          const errors = {};
          if (values?.confirmPassword !== values?.password) {
            errors.confirmPassword = "Confirm password not match";
          }
          if (
            formRef.current.errors.password &&
            !validatePassword(values.password).verified
          ) {
            errors.password = formRef.current.errors.password;
          }

          if (values.confirmPassword !== values.password) {
            errors.confirmPassword = "The confirm password not match";
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          /* and other goodies */
        }) => (
          <form className="new-password-form" onSubmit={handleSubmit}>
            <CustomPasswordInput
              fieldType="email"
              placeholder="Enter Your Email"
              handleChange={handleChange}
              fieldName="email"
              value={values.email}
              error={errors.email}
              touched={touched.email}
              handleClickInput={handleSelectField}
            />
            <CustomPasswordInput
              placeholder="Enter verification code"
              handleChange={handleChange}
              fieldName="otp"
              value={values.otp}
              error={errors.otp}
              touched={touched.otp}
              handleClickInput={handleSelectField}
            />
            <CustomPasswordInput
              fieldType="password"
              placeholder="********"
              handleChange={handleChange}
              fieldName="password"
              value={values.password}
              error={errors.password}
              touched={touched.password}
              handleBlur={handleBlur}
              handleClickInput={handleSelectField}
            />
            <CustomPasswordInput
              fieldType="password"
              placeholder="********"
              handleChange={handleChange}
              handleOnKeyPress={handleOnKeyPress}
              fieldName="confirmPassword"
              value={values.confirmPassword}
              error={errors.confirmPassword}
              touched={touched.confirmPassword}
              handleBlur={handleBlur}
              handleClickInput={handleSelectField}
            />

            <div className="submit-button-group">
              <CustomOutlineButton
                className="login-submit-button"
                label="Reset Password"
                onClick={handleResetPassword}
                disabled={
                  !isEmpty(formRef.current.errors) ||
                  !formRef.current.values?.otp ||
                  !formRef.current.values?.password
                }
              />
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default NewPasswordInputComponent;
