import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { Tooltip } from "@mui/material";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { useMemo } from "react";
import { useIsMobile } from "../hooks/common.hooks";
import { secondaryColor } from "../scss/colors.module.scss";
import { validatePassword } from "../utils/password-validate";

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "white",
    color: secondaryColor,
    fontSize: 12,
    border: `1px solid ${secondaryColor}`,
  },
}))(Tooltip);

const PasswordTooltip = ({ open = false, password }) => {
  const checkValidatePassword = useMemo(() => {
    return validatePassword(password);
  }, [password]);
  const { rules = {} } = checkValidatePassword;
  const {
    lowerValidate = false,
    minLenValidate = false,
    numberValidate = false,
    upperValidate = false,
  } = rules;
  const isMobile = useIsMobile();
  return (
    <LightTooltip
      placement={`${isMobile ? "top" : "bottom"}-start`}
      className="password-tooltip"
      open={open}
      title={
        <div className="password-validate-tooltip">
          <div>Password Requirement: </div>
          <div
            className={classNames("password-validate", {
              "is-done": minLenValidate,
            })}
          >
            <span>{minLenValidate ? <CheckIcon /> : <ClearIcon />}</span>{" "}
            Minimum 8 digits
          </div>
          <div
            className={classNames("password-validate", {
              "is-done": upperValidate,
            })}
          >
            <span>{upperValidate ? <CheckIcon /> : <ClearIcon />}</span>
            At least 1 upper case letters (A – Z)
          </div>
          <div
            className={classNames("password-validate", {
              "is-done": lowerValidate,
            })}
          >
            <span>{lowerValidate ? <CheckIcon /> : <ClearIcon />}</span>
            At least 1 Lower case letters (a – z)
          </div>
          <div
            className={classNames("password-validate", {
              "is-done": numberValidate,
            })}
          >
            <span>{numberValidate ? <CheckIcon /> : <ClearIcon />}</span>
            At least 1 number (0 – 9)
          </div>
        </div>
      }
    >
      <span />
    </LightTooltip>
  );
};

PasswordTooltip.propTypes = {};

export default PasswordTooltip;
