import React from "react";
import PropTypes from "prop-types";
import NewPasswordInputComponent from "./new-password-input-component";

const EnterNewPassword = ({ handleSetStep = null, email = "" }) => {
  const handleInput = (value) => {
    handleSetStep(value);
  };
  return (
    <>
      <div className="signup-context-title">Reset password</div>
      <div className="forgot-password-description">
        Please enter your new password. Then confirm your new password.
      </div>
      <NewPasswordInputComponent handleInput={handleInput} email={email} />
    </>
  );
};

EnterNewPassword.propTypes = {
  handleSetStep: PropTypes.func,
  code: PropTypes.string,
};

export default EnterNewPassword;
