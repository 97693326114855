import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useRef } from "react";
import { useIsMobile } from "../hooks/common.hooks";
import { secondaryColor, primaryColor } from "../scss/colors.module.scss";
import PasswordTooltip from "./password-tooltip";

const CssTextField = withStyles({
  root: {
    display: "flex",
    width: "100%",
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primaryColor,
    },
    "& .MuiOutlinedInput-root": {
      alignItems: "stretch",
      "& fieldset": {
        borderColor: secondaryColor,
      },
      "&:hover fieldset": {
        borderColor: primaryColor,
      },
      "&.Mui-focused fieldset": {
        borderColor: primaryColor,
      },
      "& input": {
        cursor: "text",
        flex: "1 1",
      },
    },
  },
})(TextField);

const CustomPasswordInput = ({
  placeholder,
  handleChange,
  fieldName,
  value,
  error = "",
  touched,
  handleBlur = () => {},
  handleClickInput = () => {},
  handleOnKeyPress = () => {},
  fieldType = "text",
  disableTooltip = false,
  label = "",
}) => {
  const inputRef = useRef();
  const isMobile = useIsMobile();
  return (
    <div className="text-left">
      {isMobile && !disableTooltip && fieldType === "password" && (
        <PasswordTooltip open={touched && !error} password={value} />
      )}
      <CssTextField
        label={label}
        inputRef={inputRef}
        type={fieldType}
        name={fieldName}
        onChange={handleChange}
        onBlur={(e) => {
          handleBlur(e.target);
          handleClickInput(e.target.name, false);
        }}
        value={value}
        onFocus={(e) => {
          handleClickInput(e.target.name, true);
        }}
        onKeyDown={handleOnKeyPress}
        variant="outlined"
        id="custom-css-outlined-input"
        autoComplete="new-password"
        helperText={error}
        error={error}
        placeholder={placeholder}
        inputProps={{
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        }}
      />
      {!isMobile && !disableTooltip && fieldType === "password" && (
        <PasswordTooltip open={touched} password={value} />
      )}
    </div>
  );
};

CustomPasswordInput.propTypes = {};

export default CustomPasswordInput;
